import React, { ComponentProps, ReactNode } from "react";
import styled from "styled-components";
import { Box } from "grommet";
import { BackgroundType } from "grommet/utils";
import SanityImage from "gatsby-plugin-sanity-image";
import { graphql } from "gatsby";

import Section from "@components/Section";
import {
  getMobileBreakpoint,
  getDesktopBreakpoint,
  pxToInt,
} from "@utils/Grommet";
import { NestedPartial } from "@utils/Types";

const Container = styled(Box)<{ heightDesktop: string; heightHD: string }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  height: ${({ heightHD }) => heightHD};

  @media (max-width: ${getDesktopBreakpoint}px) {
    height: ${({ heightDesktop }) => heightDesktop};
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    flex-direction: column-reverse;

    height: auto;
  }
`;

const ContentBox = styled(Box)`
  flex: 1 1;
  padding: 0;

  @media (max-width: ${getMobileBreakpoint}px) {
    flex: none;
    padding: 25px 0;
  }
`;

type TImageBoxProps = {
  ratio: number;
  hdWidth: number;
  desktopWidth: number;
};

const ImageBox = styled(Box)<TImageBoxProps>`
  overflow: hidden;
  height: 100%;
  width: ${({ hdWidth }) => `${hdWidth * 1.15}px`};

  @media (max-width: ${getDesktopBreakpoint}px) {
    width: ${({ desktopWidth }) => `${desktopWidth * 1.15}px`};
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    overflow: visible;
    height: ${({ ratio }) => `${290 / ratio}px`};
    width: 290px;
  }
`;

const MainImage = styled(SanityImage)`
  height: 115%;
  object-fit: cover;

  @media (max-width: ${getMobileBreakpoint}px) {
    height: auto;
    max-width: 100%;
  }
`;

type TProps = {
  children: ReactNode;
  heightDesktop?: string;
  heightHD?: string;
  background?: BackgroundType;
  seoImage?:
    | (NestedPartial<Omit<Queries.SanitySeoImage, "image">> & {
        image?: Partial<ComponentProps<typeof SanityImage>> | null;
      })
    | null;
};

const PageHero = ({
  children,
  heightDesktop = "390px",
  heightHD = "547px",
  background = "brand",
  seoImage,
}: TProps) => {
  const origImgHeight = seoImage?.image?.asset?.height;
  const origImgWidth = seoImage?.image?.asset?.width;
  const imgRatio = origImgWidth / origImgHeight;
  const imgWidthHD = Math.round(pxToInt(heightHD) * imgRatio);
  const imgWidthDesktop = Math.round(pxToInt(heightDesktop) * imgRatio);

  return (
    <Section constrained="hd" background={background}>
      <Container heightDesktop={heightDesktop} heightHD={heightHD}>
        <ContentBox>{children}</ContentBox>
        <ImageBox
          ratio={imgRatio}
          hdWidth={imgWidthHD}
          desktopWidth={imgWidthDesktop}
        >
          {seoImage?.image && (
            <MainImage
              {...seoImage?.image}
              alt={seoImage?.alt}
              width={imgWidthHD}
            />
          )}
        </ImageBox>
      </Container>
    </Section>
  );
};

export default PageHero;

export const query = graphql`
  fragment PageHero on SanitySeoImage {
    image {
      ...ImageWithPreview

      asset {
        url
        width
        height
      }
    }
    alt
  }
`;
