import React, { useCallback, useRef } from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";
import { Box, Heading, Grid } from "grommet";
import { PortableText } from "@portabletext/react";

import Seo from "@components/Seo";
import Section from "@components/Section";
import PageHero from "@components/PageHero";
import Button from "@components/Button";
import Paragraph from "@components/Paragraph";
import SanityImage from "@components/SanityImage";
import HubSpotSectionContent from "@components/HubSpotSectionContent";
import { defaultComponents } from "@utils/PortableText";
import { getMobileBreakpoint, getDesktopBreakpoint } from "@utils/Grommet";

const HeroContent = styled(Box)`
  padding-right: 150px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    padding-right: 100px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    padding-right: 0px;
    /* max-width: 350px; */
  }
`;

const FeatureBox = styled(Box)`
  align-items: flex-start;

  @media (max-width: ${getMobileBreakpoint}px) {
    align-items: center;
  }
`;

const FeatureGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 48px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    grid-gap: 36px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    grid-template-columns: 1fr;

    max-width: 260px;
  }
`;

type TPrivacyQuery = Queries.FeaturesQuery;

const FeaturesPage = ({
  data: { site },
  location,
}: PageProps<TPrivacyQuery>) => {
  const hsRef = useRef<HTMLDivElement>(null);

  const handleHsScroll = useCallback(() => {
    hsRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [hsRef?.current]);

  const categories = site?.featuresfeatureCategories;

  return (
    <Box>
      <Seo
        title="Features"
        description={site?.featuresMetaDescription ?? undefined}
        ogImage={site?.featuresHero?.image?.asset?.url}
        ogUrl={location?.href}
      />

      <PageHero seoImage={site?.featuresHero}>
        <HeroContent align="start" margin={{ bottom: "small" }}>
          <Heading
            level={1}
            size="large"
            color="grey-10"
            margin={{ bottom: "xsmall" }}
          >
            Features
          </Heading>
          <Paragraph size="large" margin={{ bottom: "small" }}>
            {site?.featuresIntro}
          </Paragraph>

          <Button
            label="Get in touch"
            onClick={handleHsScroll}
            background="grey-10"
            color="grey-3"
            size="large"
          />
        </HeroContent>
      </PageHero>

      {categories?.map((category, index) => (
        <Section
          key={index}
          constrained="hd"
          background={index % 2 === 0 ? "grey-10" : "grey-3"}
          pad={{ vertical: "xlarge" }}
        >
          <Heading level={2} margin={{ bottom: "large" }}>
            {category?.title}
          </Heading>

          <FeatureBox>
            <FeatureGrid>
              {category?.features?.map((feature, index) => (
                <Box key={index} gap="medium">
                  {feature?.image?.image && (
                    <SanityImage
                      {...feature?.image?.image}
                      alt={feature?.image?.alt}
                      imgwidth="80%"
                    />
                  )}

                  <PortableText
                    value={feature?._rawDescription as any}
                    components={defaultComponents({
                      hLevel: 3,
                      hSize: "small",
                    })}
                  />
                </Box>
              ))}
            </FeatureGrid>
          </FeatureBox>
        </Section>
      ))}

      <Section ref={hsRef} constrained="hd" background="brand">
        <HubSpotSectionContent
          hsPortalId={site?.hsPortalId}
          hsFormId={site?.hsFormId}
        />
      </Section>
    </Box>
  );
};

export default FeaturesPage;

export const query = graphql`
  query Features {
    site: sanitySite {
      _id
      id

      featuresHero {
        ...PageHero
      }

      featuresIntro
      featuresMetaDescription

      featuresfeatureCategories {
        title
        features {
          image {
            image {
              ...ImageWithPreview
            }
            alt
          }
          _rawDescription
        }
      }

      ...HubSpotForm
    }
  }
`;
